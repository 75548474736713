import { Component, OnInit } from '@angular/core';

import { Input, Output, EventEmitter } from '@angular/core';

import { FormGroup, FormControl } from '@angular/forms';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';

import * as sha1 from 'js-sha1';




const httpOptions = {
  headers: new HttpHeaders(
    {
      'Content-Type': 'application/json',
    }
  )
};

interface UserInput {
    iduser: number;
    prenom: string;
} ;



@Component({
  selector: 'app-authentification',
  templateUrl: './authentification.component.html',
  styleUrls: ['./authentification.component.css']
})
export class AuthentificationComponent implements OnInit {

   login = "" ;
   pwd = "" ;

   mustChangepwd = 0 ;
   nwpwd = "" ;
   nwpwdconfirmed = "" ;

   erreurLogin = 0 ;
   erreurCoher = 0 ;

   fdg = 0 ;


  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
  }


  farLogin(){    
    let logdata = {login: sha1(this.login) , pwd: sha1(this.pwd) } ;  

    console.log(logdata) ;

    this.remotelogger('https://transferts.smkfinances.com/transferts/yeurrlouweb.php', logdata ).subscribe(
    //this.remotelogger('http://144.91.121.43/lis/yeurrlouBO.php', logdata ).subscribe(
        retourPost => {

          console.log(retourPost) ;

          //retourPost = {"iduser": 3, "accessLevel": 10, "firstuse" : 0} ;

          if (retourPost["iduser"]!=undefined && (retourPost["accessLevel"]==0 || retourPost["accessLevel"]==1) ){
            this.erreurLogin = 0 ;

            sessionStorage.setItem("accessl", retourPost["accessLevel"].toString()) ;
            sessionStorage.setItem("samacaabiyoonwii", retourPost["token"].toString()) ;

            if (retourPost["firstuse"]!=1){
             let usrInfos = retourPost ;
             this.router.navigate(['/homecpnt/partners']) ;
            }else{
              this.mustChangepwd = 1 ;
              this.fdg = retourPost["iduser"] ;
            }
          }
          else{
            console.log("FAILED") ;
            this.erreurLogin = 1 ;
          }
    } ) ;    
          

  }

  updatePwd(){

    if (this.nwpwdconfirmed!=this.nwpwd)
      this.erreurCoher = 1 ;
    else{
      let updtedata = {iduser: this.fdg , nwpwd: sha1(this.nwpwd) } ;       
      this.erreurCoher = 0 ;

      this.remoteupdtepwd('https://transferts.smkfinances.com/transferts/sopandikouleiCaabi.php', updtedata).subscribe(
          retourPost => {
            if (retourPost!="0"){
              this.login = "" ;
              this.pwd = "" ;
              this.mustChangepwd = 0 ;
            }
            else
              console.log("FAILED Update") ;
      } ) ;

    }
  }

  remotelogger(url: string, user: any) {
    return this.http.post(url, user, httpOptions);
  }

  remoteupdtepwd(url: string, user: any) {
    return this.http.post(url, user, httpOptions);
  }


}
